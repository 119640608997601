import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from '@external/react-i18next';
import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';
import { useFetchClubInvoiceData } from '@repositories/clubs/hooks';
import { RIBI_COUNTRY_LIST } from '@domain/common';
import Button from '@rotaryintl/harris-button';
import Alert from '@rotaryintl/harris-alert';
import { log } from '@hooks/logger';
import { LogLevel } from '@typings/graphql';
import { DIS } from '@typings/dis';
import { localizedNavigate } from '@utils/localized-navigate';
import {
  getClubFinancePath,
  getClubIdFromPath,
  getClubInvoicePath,
} from '@use-cases/clubs';

interface BackButtonProps {
  t: (key: string, defaultValue?: string) => string;
  onBackClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ t, onBackClick }) => (
  <Button
    clickHandler={onBackClick}
    icon="fas-angle-left"
    size="md"
    variant="text"
    className="mt-6"
  >
    {t('back-link-text', 'Back')}
  </Button>
);

interface Props extends RouteComponentProps {
  clubId: string;
}

const ClubInvoiceReport: React.FC<Props> = ({ clubId }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const docType = urlParams.get('docType') || 'I';

  const { t } = useTranslation();

  const { data: clubData, loading: clubDataLoading } = useFetchClubInvoiceData(
    clubId
  );

  const data = clubData?.club;

  if (clubDataLoading || !data) {
    return <Loading />;
  }

  const isManager = clubData.club.operationsAccess.leaderships === 'Manager';
  const clubCountry = data.physicalLocation?.country || '';
  const isRibiCountry =
    RIBI_COUNTRY_LIST.includes(clubCountry) &&
    data.clubType === DIS.ClubTypeEnum.RotaryClub;

  const storedActiveTabData = window.localStorage.getItem('activeTabValue');
  const activeTabData = storedActiveTabData
    ? JSON.parse(storedActiveTabData)
    : [];
  const navigatedFromActiveClubTabs = activeTabData[0]?.value;

  const handleBackNavigation = () => {
    let redirectToClubFinancePath = '/my-clubs';
    if (navigatedFromActiveClubTabs) {
      const homeClubId = getClubIdFromPath(navigatedFromActiveClubTabs);
      redirectToClubFinancePath = getClubFinancePath(homeClubId);
    }
    localizedNavigate(redirectToClubFinancePath);
  };

  if (!isManager) {
    return (
      <OneColumn className="mb-20">
        <BackButton t={t} onBackClick={handleBackNavigation} />
        <AccessDenied />
      </OneColumn>
    );
  }

  log({
    level: LogLevel.Info,
    message: `Club Finance Report is loaded for club: ${clubId}`,
  });

  return (
    <OneColumn className="mb-20">
      <BackButton
        t={t}
        onBackClick={() => localizedNavigate(getClubInvoicePath(clubId))}
      />
      <h1 className="font-semibold text-xl-38 mobile-m:text-xl-48 tablet:text-xl-58 tablet:p-0 m-0 my-6 tablet:mb-8 tablet:leading-xl-58">
        {t('club-invoice.report-title', 'Club Invoice Report')}
      </h1>
      {!isRibiCountry && (
        <p>
          {docType === 'I'
            ? t(
                'club-invoice.report-download-msg',
                'We are downloading club invoice report for you and opening it in new tab.'
              )
            : t(
                'club-invoice.details-report-download-msg',
                'We are downloading club invoice details report for you and opening it in new tab.'
              )}
        </p>
      )}
      {isRibiCountry && (
        <div id="ribiCountryNote" className="mb-60 w-2/3">
          <Alert variant="info" isDismissible={false}>
            {t(
              'club-invoice.ribi-countries-note',
              'Please go to <a href="https://dms.rotarygbi.org/login" target="_blank"><b>https://dms.rotarygbi.org/login</b></a> to access your club’s financials.'
            )}
          </Alert>
        </div>
      )}
    </OneColumn>
  );
};

export default ClubInvoiceReport;
